<template>
  <BadgeGroup>  
    <UTooltip
      v-for="val in values" 
    >
      <UBadge 
        color="secondary"
        size="xs"
        class="whitespace-nowrap"
        variant="soft"
      >
        <span>{{val.label}}</span>
      </UBadge>
      <template #text>
        <span class="whitespace-normal">
          {{ val.description }}
        </span>
      </template>
    </UTooltip>
  </BadgeGroup>
  
</template>

<script lang="ts" setup>
const props = defineProps<{ data: string[]}>()


const { bevoegdheden } = useConstants()
const values = computed(() => {
  return props.data.map(sec => {
    const rec = bevoegdheden.find(s => sec === s.id || sec === s.label || s.altNames.includes(sec))

    if (!rec) return ''

    return rec

  }).filter(val => !!val)
  
})
</script>

<style>

</style>